/***** 正式环境 *****/
export const IsDebug = false;
export const BaseUrl = "https://api.member.gxuafa.cn/";
export const RedirectServerUrl = "https://redirect.wenovo.cn/api_member_gxuafa_cn.php";

// /***** 本地测试环境 *****/
// export const IsDebug = true;
// export const BaseUrl = "http://localhost:5000/";
// export const RedirectServerUrl = "https://redirect.wenovo.cn/test_api_member_gxuafa_cn.php";

/***** 网络测试环境 *****/
// export const IsDebug = true;
// export const BaseUrl = "https://test.api.member.gxuafa.cn/";
// export const RedirectServerUrl = "https://redirect.wenovo.cn/test_api_member_gxuafa_cn.php";

/***** 控制台登录测试环境 *****/
// export const IsDebug = false;
// export const BaseUrl = "https://test.api.member.gxuafa.cn/";
// export const RedirectServerUrl = "https://redirect.wenovo.cn/test_api_member_gxuafa_cn.php";

// export const BaseUrl = "https://test.api.member.gxuafa.cn/";
// export const RedirectUrl = "https://redirect.wenovo.cn/"


// export const WechatLoginRedirect = "https://test.api.member.gxuafa.cn/user/login-wechat/";


export const WechatAppID = "wx5184b6d6d0516d3b";
export const WechatLoginUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid={0}&redirect_uri={1}&response_type=code&scope=snsapi_userinfo&state={2}#wechat_redirect";

export const UserImage = "./static/user-image/"

/**审核分会所交会费的商品ID号 */
// export const ChangePartyGoodsId = 0;

export const UserToken = "a098c04e02f748d7be1b53a00bad8df1";
export const Platform = "Wechat";
